// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--11-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--11-oneOf-1-2!../../../node_modules/postcss-loader/src/index.js??ref--11-oneOf-1-3!../../assets/css/workplatform3.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/boot.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".menu-item[data-v-019c17ec]{height:calc(100vh - 190px);overflow-y:scroll;scrollbar-width:none;-ms-overflow-style:none}.menu-item[data-v-019c17ec]::-webkit-scrollbar{display:none}.ivu-menu-vertical .ivu-menu-item[data-v-019c17ec],.ivu-menu-vertical .ivu-menu-submenu-title[data-v-019c17ec]{padding:8px 0;font-size:14px}.menu-item span[data-v-019c17ec]{display:inline-block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;vertical-align:bottom;transition:width .2s ease .2s;font-size:14px}.menu-item i[data-v-019c17ec]{transform:translateX(0);transition:font-size .2s ease,transform .2s ease;vertical-align:middle;font-size:14px}.menu-item[data-v-019c17ec] .ivu-menu-submenu-title{padding:10px 5px 10px 16px}.menu-item[data-v-019c17ec] .ivu-menu-submenu-title-icon{right:0}.ivu-dropdown-item[data-v-019c17ec]:hover{background:#40434a;color:#fff;opacity:.9}.platform-submenu[data-v-019c17ec]{width:48px}.icon-btn[data-v-019c17ec]{cursor:pointer}.icon-btn[data-v-019c17ec]:hover{color:#2b85e4}.platform[data-v-019c17ec]{display:block;padding:10px 0;border-radius:3px}.platform[data-v-019c17ec]:hover{color:#5cadff;border-color:#5cadff}.platform-actived[data-v-019c17ec]{color:#2d8cf0;border-color:#2b85e4}[data-v-019c17ec] .ivu-drawer-body{padding:0}.ooh-ai[data-v-019c17ec]{width:40px;height:40px;position:fixed;right:8px;top:30%;cursor:pointer;z-index:9999;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}", ""]);
// Exports
module.exports = exports;
